import './styles.scss';
import React, { useState, useRef, forwardRef } from 'react';
import * as ExcelJS from 'exceljs';
import { jsPDF } from "jspdf";
import LoaderCirculo from '../../../../../assets/icons/circulo_loader.webp'
import LogoSquad from '../../../../../assets/images/logo.png'
import "jspdf-autotable";
import Swal from 'sweetalert2';

import { Close } from '@icon-park/react';

import GmailIcon from './icons/gmail-svgrepo-com.svg'
import WhatsappIcon from './icons/whatsapp-color-svgrepo-com.svg'
import PdfIcon from './icons/pdf-file-svgrepo-com.svg'
import ExcelIcon from './icons/excel-svgrepo-com.svg'
import api from 'services/api/api';

import Loader from '../../../../../assets/icons/circulo_loader.webp'

const SharedComponent = forwardRef(({onClose, item, setIsOpeSendEmailExport, ...rest},ref) => {


    // console.log('my item:',  item)

    
    const [isLoading, setIsLoading] = useState(false);

    async function handleExportExcel(data) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("ResultadoConsulta");

        const headers = data.length > 0 && data[0].message.value.length > 0 
        ? Object.keys(data[0].message.value[0]).map(header => header.replace(/\[|\]/g, '')) 
        : [];

        worksheet.columns = headers.map(header => ({ header, key: header, width: 25 }));

        worksheet.getRow(1).font = { bold: true, color: { argb: '0000' } };
        worksheet.getRow(1).fill = {
            fillType: 'solid',
            fgColor: { argb: '0078D7' }
        };

        headers.forEach((_, index) => {
            const cell = worksheet.getCell(1, index + 1);
            cell.alignment = { vertical: 'middle', horizontal: 'left' };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        data.forEach(item => {
            const rows = item.message.value.length > 0
                ? item.message.value.map(value => Object.values(value))
                : [];

            rows.forEach(row => {
                const newRow = worksheet.addRow(row);
                newRow.eachCell((cell) => {
                    cell.alignment = { vertical: 'middle', horizontal: 'left' };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });
        });

        worksheet.columns.forEach(column => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                if (cell.value && cell.value.toString().length > maxLength) {
                    maxLength = cell.value.toString().length;
                }
            });
            column.width = Math.max(maxLength + 2, 25);
        });

        worksheet.addRow([]);

        const footerRow = worksheet.addRow(["Gerado por [Squad AI/Squadbi LTDA]"]);
        footerRow.getCell(1).font = { italic: true, size: 12 };
        footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

        footerRow.height = 20;

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        const a = document.createElement('a');
        a.href = url;
        a.download = `resumo_consulta${formattedDate}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }


    async function handleExportPdf(data) {

        try {
            const doc = new jsPDF('l');
            // const logoImg = await loadImageAsBase64(LogoSquad);
            // const logoImg = await loadImageFromServer()
            // const logoDefaultSquadbi = await loadLocalImage(LogoSquad)
            // console.log('logoImg', logoImg)
            // const logoWidth = 43;
            // const logoHeight = 15;

            // const title = "Resultados da Consulta";
            // doc.setFontSize(24);
            // doc.setFont("Arial", "bold");
            doc.setTextColor(0, 120, 215);

            // const titleWidth = doc.getTextWidth(title);
            // const centerX = doc.internal.pageSize.width / 2;
            // const logoY = 80;
            // const titleY = logoY + logoHeight + 13;

            // console.log('logoImg');
            // console.log('default', logoDefaultSquadbi)


            // if (logoImg) {
            //     doc.addImage(logoImg, 'PNG', (doc.internal.pageSize.width - logoWidth) / 2, logoY, logoWidth, logoHeight);
            // } else {
            //     doc.addImage(logoDefaultSquadbi, 'PNG', (doc.internal.pageSize.width - logoWidth) / 2, logoY, logoWidth, logoHeight);
            // }

            // doc.text(title, centerX - titleWidth / 2, titleY);
            // doc.addPage();

            const maxLinesPerPage = 13;

            const allRows = [];
            const headers = data.length > 0 && data[0].message.value.length > 0 
                ? Object.keys(data[0].message.value[0]).map(header => header.replace(/\[|\]/g, '')) 
                : [];
    
            data.forEach(item => {
                const rows = item.message.value.length > 0 ? item.message.value.map(value => Object.values(value)) : [];
                allRows.push(...rows);
            });
    
            const tableOptions = {
                head: [headers],
                theme: 'grid',
                headStyles: {
                    fillColor: [0, 120, 215],
                    textColor: [255, 255, 255],
                    fontStyle: 'bold',
                    halign: 'left',
                    cellPadding: 5,
                },
                bodyStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineHeight: 1.5,
                    cellPadding: 4,
                },
                alternateRowStyles: {
                    fillColor: [240, 240, 240],
                },
                styles: {
                    minCellHeight: 10,
                    fontSize: 10,
                    halign: 'left',
                    tableWidth: doc.internal.pageSize.width - 20, // Ajuste da largura
                },
            };
    
            let i = 0;
            while (i < allRows.length) {
                const rowsToShow = allRows.slice(i, i + maxLinesPerPage);
                const startY = 16; // Altura fixa para o início da tabela
    
                doc.autoTable({
                    ...tableOptions,
                    startY: startY,
                    body: rowsToShow,
                });
    
                i += rowsToShow.length;
    
                if (i < allRows.length) {
                    doc.addPage();
                }
            }
    
            const pageCount = doc.internal.getNumberOfPages();
            const footerMargin = 10;
            const footerText = "Resultado gerado por [Squad AI/Squadbi LTDA]";
            for (let pageIndex = 1; pageIndex <= pageCount; pageIndex++) {
                doc.setPage(pageIndex);
                doc.setFontSize(10);
                doc.setFont("Helvetica", "normal");
                doc.text(footerText, 14, doc.internal.pageSize.getHeight() - footerMargin);
            }
    
            doc.save(`resumo_consulta${new Date().toISOString().split('T')[0]}.pdf`);
        } catch (error) {
            console.error("Erro durante a exportação para PDF:", error);
        } 
    }


    return (
        <div className="modal-overlay" ref={ref}>
            <div className="modal-content-shared">
                {/* <div className='close-container'>
                    <button className="close-button" onClick={onClose}>
                        <Close size="15" fill="#666" />
                    </button>
                </div> */}
                <ul className="share-options">
                    <li
                        // onClick={() => handleSendEmailPdf(item)}
                        onClick={() => setIsOpeSendEmailExport(true)}
                        className={'email'}
                    >
                        {/* <img src={GmailIcon} alt='gmail' style={{ width: '22px' }} /> */}
                        {isLoading ? <img src={LoaderCirculo} style={{width: '21px', height: '21px'}} />  : <img src={GmailIcon} alt='gmail' style={{ width: '22px' }} />}
                    </li>
                    <li
                        onClick={() => {}}
                        className={'whatsapp'}
                    >
                        <button style={{margin: '0px', padding: '0px', background: 'transparent'}} disabled={true} className='block'>
                            <img src={WhatsappIcon} alt='whatsapp' style={{ width: '22px' }} /> 
                        </button>
                    </li>
                    <li
                        onClick={() => handleExportExcel(item)}
                        className={'excel'}
                    >
                        <img src={ExcelIcon} alt='excel' style={{ width: '22px' }} />
                    </li>
                    <li
                        onClick={() => handleExportPdf(item)}
                        className={'pdf'}
                    >
                        <img src={PdfIcon} alt='pdf' style={{ width: '22px' }} />
                    </li>
                </ul>
            </div>
        </div>
    );
})

export default SharedComponent;

