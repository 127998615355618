import './style.scss'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function DefaultButton({action, placeholder, isDisabled, execution}) {

    const { t } = useTranslation();

    return (
        <button 
            type={
                action === 'save' ? 'submit' : 'button'
            } 
            className={
                `save-button-container ${action} ${isDisabled && 'disabled'}`
            }
            disabled={isDisabled}
            onClick={execution}
        >
            {action === 'save' ? placeholder : t('userPage.form.back')}
        </button>
    )
}