import './styles.scss'
import axios from 'axios'
import { AuthContext } from 'contexts/appProvider'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import api from 'services/api/api'
// import { ReportsContext } from 'contexts/reportsContext' //comentei
import Logo from '../../../assets/images/squadia.png'
import { withRouter } from 'react-router-dom';
import { PulseLoader } from 'react-spinners'
import { Clear, FullSelection, ShareTwo } from '@icon-park/react'
import { Tooltip } from '@mui/material'
import Swal from 'sweetalert2'
import Messages from './components/messages'
import { AssistantBallon } from './components/assistant-ballon'
import { storage } from 'utils'
import SharedComponent from './components/shared'
import ModalEmail from '../modalEmail'

function SquadIaDfeault() {

    const getUrl = window.location.href
    const resultsAreaRef = useRef(null);
    const [tokenCache, setTokenCache] = useState(null);
    const [isAtBottom, setIsAtBottom] = useState(true);

    const { userData, idCurrentReport, setActiveIA } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true)

    const [assistantId, setAssistantId] = useState(null)

    const [filtredAssistants, setFiltredAssistants] = useState([])

    const [words, setWords] = useState("");
    const [isAwaitResponseMessage, setIsAwaitResponseMessage] = useState(false)

    const [restoreLoading, setRestoreLoading] = useState(false)

    const [messages, setMessages] = useState([]);

    const [isOpenSendEmailExport, setIsOpeSendEmailExport] = useState(false)

    const loadFirst = useRef(false)


    // check messages
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [openModalExport, setOpenModalExport] = useState(false)

    const toggleMessageSelection = (item) => {
        setSelectedMessages(prev => {
          if (prev.includes(item)) {
            return prev.filter(selectedItem => selectedItem !== item);
          } else {
            return [...prev, item];
          }
        });
      };
    
    const getAssistants = useCallback(async (dataset) => {
        try {
            const res = await api.post('/assistants')
            if(res.data && res.data.status && res.data.assistants && res.data.assistants.length > 0) {
                
                const allAssistants = res.data.assistants;
    
                if (userData.login === "admin") {
                    allAssistants.forEach(assistant => assistant.isAdmin = true);
                    setFiltredAssistants(allAssistants);
    
                    if (dataset) {
                        const findDataset = allAssistants.find((item) => item.dataset === dataset.datasetId);
                        if (findDataset) {
                            switchAssistant(findDataset.id_chatgpt);
                        }
                    }
                    return;
                }
    
                const assistantsFiltred = allAssistants.filter(assistant => {
                    let userAccess = false;
                    let isAdmin = false;
    
                    if (assistant.usuarios) {
                        assistant.usuarios.forEach(usuario => {
                            if (usuario.id == userData.id_usuario) {
                                userAccess = true;
                                if (usuario.admin == "1") {
                                    isAdmin = true;
                                }
                            }
                        });
                    }
    
                    if (!userAccess && assistant.grupos) {
                        assistant.grupos.forEach(grupo => {
                            if (grupo.id == userData.grupoUsuario) {
                                userAccess = true;
                            }
                        });
                    }
    
                    if (userAccess) {
                        if (isAdmin) {
                            assistant.isAdmin = true;
                        }
                        return true;
                    }
    
                    return false;
                });
    
                if(dataset) {
                    const findDataset = assistantsFiltred.find((item) => item.dataset === dataset.datasetId)
                    if(findDataset) {
                        switchAssistant(findDataset.id_chatgpt)
                    }
                }

                setFiltredAssistants(assistantsFiltred);

            }
        } catch (error) {
            console.error('Erro ao buscar assistentes:', error);
        }
    }, [])

    async function requestReportsInfo(name) {
        try {
            const response = await api.post('/reportsInfo-v2', {
                "nome": name
            })
            return response.data
        } catch (error) {
            return error
        }
    }

    const getDatasetReport = useCallback(async (groupId, reportId) => {
        try {
            const token = await captureToken()
            const config = { headers: { 'Authorization': 'Bearer ' + token } };
            const res = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}`, config)
            const report = res.data
            return {datasetId: report.datasetId, datasetWorkspaceId: report.datasetWorkspaceId}
        } catch (error) {
            return
        }
    }, [])

    const getAll = useCallback(async () => {
        try {
            captureToken()
            setIsLoading(true)
            if(getUrl.includes('/viewReport')) {
                const report = findCurrentReport();
                const dataset = await getDatasetReport(report.embedded_workspace, report.embedded_relatorio)
                await getAssistants(dataset)
                // await new Promise(r => setTimeout(r, 3000));
                setIsLoading(false) 
            } else {
                await getAssistants()
                // await new Promise(r => setTimeout(r, 3000));
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    }, [])

    function findCurrentReport(){

        let reports = []
        if(userData){
          const telas = Object.values(userData.telas)
          const relatorios = Object.values(userData.relatorios)
      
          if(Array.isArray(telas)){
            if(telas.length){
              for (const tela of telas[0]) {
                reports.push(tela)
              }
            }
          }
      
          if(Array.isArray(relatorios)){
            if(relatorios.length){
              for (const relatorio of relatorios) {
                  if(Array.isArray(relatorio)){
                    if(relatorio.length){
                      reports.push(...relatorio)
                    }
                  }
                }
              }
            }
    
            const item = reports.find(report => report.embedded_relatorio == idCurrentReport)
    
            return item
        }
    
    }

    const captureToken = useCallback(async () => {
        
        const currentTime = Date.now();
        const expirationTime = 45 * 60 * 1000;
    
        if (tokenCache && (currentTime - tokenCache.timestamp < expirationTime)) {
          return tokenCache.token;
        }
    
        try {
            const token = await requestReportsInfo('SquadAI');
          
            const newTokenCache = {
                token: token.token,
                timestamp: currentTime
            };
    
            setTokenCache(newTokenCache);
            return token.token;

        } catch (error) {
            console.error('Erro ao capturar token:', error);
            throw error; 
        }
    }, [tokenCache]);

    useEffect(() => {getAll()}, [])

    // == switch assistant 
    async function switchAssistant(id_assistant) {
        try {
            setIsLoading(true);
            setAssistantId(id_assistant);
            setMessages([]);
            
            const res = await axios.get(`https://hooks.squadbi.app/chat/findChatActive/${Number(userData.id_usuario)}/${id_assistant}`);
            
            if (res.data && res.data.chat && res.data.chat.length > 0) {

                const oldMessage = res.data.chat;  

                let skipItem = false;
                const filteredMessages = [];

                for (let currentId = 0; currentId < oldMessage.length; currentId++) {
                    const currentItem = oldMessage[currentId];
                
                    if (skipItem) {
                        skipItem = false;
                        continue;
                    }
                
                    if (
                        currentItem.role === 'user' &&
                        (
                            currentItem.content.includes('A querie ao ser executada retornou o seguinte erro') ||
                            currentItem.content.includes('A querie ao ser executada não retornou nenhum registro')
                        )
                    ) {
                        skipItem = true;
                        continue;
                    }
                
                    if (currentItem.role !== 'user') {
                        if (
                            oldMessage[currentId + 1] &&
                            oldMessage[currentId + 1].role === 'user' &&
                            (
                                oldMessage[currentId + 1].content.includes('A querie ao ser executada retornou o seguinte erro') ||
                                oldMessage[currentId + 1].content.includes('A querie ao ser executada não retornou nenhum registro')
                            )
                        ) {
                            if (
                                oldMessage[currentId + 3] &&
                                oldMessage[currentId + 3].role === 'user' &&
                                (
                                    oldMessage[currentId + 3].content.includes('A querie ao ser executada retornou o seguinte erro') ||
                                    oldMessage[currentId + 3].content.includes('A querie ao ser executada não retornou nenhum registro')
                                )
                            ) {
                                if (
                                    oldMessage[currentId + 5] &&
                                    oldMessage[currentId + 5].role === 'user' &&
                                    (
                                        oldMessage[currentId + 5].content.includes('A querie ao ser executada retornou o seguinte erro') ||
                                        oldMessage[currentId + 5].content.includes('A querie ao ser executada não retornou nenhum registro')
                                    )
                                ) {
                                    filteredMessages.push({
                                        message: oldMessage[currentId + 6].content,
                                        isBot: oldMessage[currentId + 6].role !== 'user',
                                        activeAnimation: false,
                                        oldMessage: true,
                                    });
                                    continue;
                                } else {
                                    filteredMessages.push({
                                        message: oldMessage[currentId + 4].content,
                                        isBot: oldMessage[currentId + 4].role !== 'user',
                                        activeAnimation: false,
                                        oldMessage: true,
                                    });
                                    continue;
                                }
                            } else {
                                filteredMessages.push({
                                    message: oldMessage[currentId + 2].content,
                                    isBot: oldMessage[currentId + 2].role !== 'user',
                                    activeAnimation: false,
                                    oldMessage: true,
                                });
                            }
                            continue;
                        } else {
                            filteredMessages.push({
                                message: currentItem.content,
                                isBot: currentItem.role !== 'user',
                                activeAnimation: false,
                                oldMessage: true,
                            });
                            continue;
                        }
                    } else {
                        filteredMessages.push({
                            message: currentItem.content,
                            isBot: currentItem.role !== 'user',
                            activeAnimation: false,
                            oldMessage: true,
                        });
                    }
                }
    
                setMessages(filteredMessages);
            } else {
                setMessages([]);
            }
            
            setIsLoading(false);
            scrollToBottom();
        } catch (error) {
            console.error(error);
        }
    }  

    // == send prompt

    function sendPromptF(e) {
        e.preventDefault();
        if(!isAwaitResponseMessage) {
            sendPrompt()
        }

    }

    async function sendPrompt() {
        try {

            if(isAwaitResponseMessage || assistantId === null || restoreLoading) {
                return
            }

            const message = words

            if(message.trim().length == 0)  {
                return
            }

            const startTime = new Date();

            let stateMessages = [...messages];
            stateMessages.push({message, isBot: false, activeAnimation: true})
            setWords("")
            scrollToBottom()
            setMessages(stateMessages)
        
            setIsAwaitResponseMessage(true)

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                }
            };
            
            const body = {
                "user_id": Number(userData.id_usuario),
                "assistant_id": assistantId,
                message
            }      
            
            api.post('/chatLog', {message: message})
            const res = await axios.post('https://hooks.squadbi.app/chat', body, config)

            const botResponse = res.data.response.response
            
            const messageQuerie = await executeQuerie(JSON.parse(removeJsonIfPresent(botResponse)).querie_dax)

            const endTime = new Date();
            const timeTaken = endTime - startTime;

            const finalMessage = {
                "description": JSON.parse(botResponse).descricao_dax,
                "value": messageQuerie.value,
                "querie": messageQuerie.querie, 
                "time": timeTaken
            }

            stateMessages.push({message: finalMessage, isBot: true, activeAnimation: true, oldMessage: false})
            setMessages(stateMessages)
            
            scrollToBottom()
            setIsAwaitResponseMessage(false)
        } catch (error) {
            console.error(error)
        }
    }

    async function resendPrompt(message) {
        try {
            const chatBody = {
                "user_id": Number(userData.id_usuario),
                "assistant_id": assistantId,
                message
            };
    
            const res = await axios.post('https://hooks.squadbi.app/chat', chatBody);
            const botResponse = JSON.parse(removeJsonIfPresent(res.data.response.response)).querie_dax;
    
            return botResponse;
        } catch (error) {
            return error;
        }
    }

    async function executeQuerie(query) {
        try {
            const token = await captureToken();
            const config = { headers: { 'Authorization': 'Bearer ' + token } };
            const assistant_info = filtredAssistants.find(item => item.id_chatgpt === assistantId);
            const body = {
                "queries": [
                    {
                        "query": query,
                    }
                ]
            };
    
            const response = await axios.post(`https://api.powerbi.com/v1.0/myorg/groups/${assistant_info.workspace}/datasets/${assistant_info.dataset}/executeQueries`, body, config);
    
            if (response.status === 200 && response.data && response.data.results && response.data.results[0].tables[0].rows) {
                const rows = response.data.results[0].tables[0].rows;
    
                if (rows.length === 0) {
                    throw new Error("A querie ao ser executada não retornou nenhum registro, solicito que melhore a análise. Sempre leve em consideração as descrições contidas no schema");
                }
    
                return {value: rows, querie: query};
            } else {
                throw new Error("A querie ao ser executada não retornou nenhum registro, solicito que melhore a análise. Sempre leve em consideração as descrições contidas no schema");
            }
    
        } catch (error) {

            let attempts = 0;
            let errorMessage = "Erro desconhecido";
            let lastQuery = '';

            if (error.response && 
                error.response.data && 
                error.response.data.error && 
                error.response.data.error["pbi.error"] && 
                error.response.data.error["pbi.error"].details && 
                error.response.data.error["pbi.error"].details[0] && 
                error.response.data.error["pbi.error"].details[0].detail) {
                
                errorMessage = `A querie ao ser executada retornou o seguinte erro: ${error.response.data.error["pbi.error"].details[0].detail.value}, Aplique a devida correção e me retorne uma nova querie correta.`;
            } else if (error.message) {
                errorMessage = error.message;
            }

            let currentErrorMessage = errorMessage;
    
            while (attempts < 3) {
                attempts++;
    
                try {
                    const config = { headers: { 'Authorization': 'Bearer ' + await captureToken() } };
                    const assistant_info = filtredAssistants.find(item => item.id_chatgpt === assistantId);
    
                    const botResponse = await resendPrompt(currentErrorMessage);
                    lastQuery = botResponse;
    
                    const newBody = {
                        "queries": [
                            {
                                "query": botResponse,
                            }
                        ]
                    };
    
                    const retryResponse = await axios.post(`https://api.powerbi.com/v1.0/myorg/groups/${assistant_info.workspace}/datasets/${assistant_info.dataset}/executeQueries`, newBody, config);
    
                    if (retryResponse.status === 200) {
                        const retryRows = retryResponse.data.results[0].tables[0].rows;
    
                        if (retryRows.length === 0) {
                            currentErrorMessage = "A querie ao ser executada não retornou nenhum registro, solicito que melhore a análise. Sempre leve em consideração as descrições contidas no schema";
                        } else {
                            return {value: retryRows, querie: botResponse};
                        }
                    }
    
                } catch (retryError) {
                    const newErrorFeedback = retryError.response.data.error["pbi.error"].details[0].detail.value || "Erro desconhecido";
                    currentErrorMessage = `A querie ao ser executada retornou o seguinte erro: ${newErrorFeedback}, Aplique a devida correção e me retorne uma nova querie correta.`;                    
                }
            }
    
            return {"value": [], "querie": lastQuery};
        }
    }

    function removeJsonIfPresent(str) {  
        const regex = /```(?:json)?\s*([\s\S]*?)\s*```/g;
        const cleanedString = str.replace(regex, '$1');
        return cleanedString.trim();
    }

    async function clearChat() {
        try {
            setIsLoading(true)
            const res = await axios.get(`https://hooks.squadbi.app/chat/clearChat/${Number(userData.id_usuario)}/${assistantId}`)
            if(res.status === 200) {
                setIsLoading(false)
                setMessages([])
            } else {
                setIsLoading(false)
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: "Ocorreu algum problema ao limpar conversa.",
                    showConfirmButton: false,
                    timer: 5000
                });
            }
        } catch (error) {
            setIsLoading(false)
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Ocorreu algum problema ao limpar conversa.",
                showConfirmButton: false,
                timer: 5000
            });
            return error;
        }
    }

    // == Manipulador de scroll

    const scrollToBottom = () => {
        if (resultsAreaRef.current) {
            resultsAreaRef.current.scrollTop = resultsAreaRef.current.scrollHeight;
        }
    };

    const scrollToTop = () => {
        if (resultsAreaRef.current) {
            resultsAreaRef.current.scrollTop = 0;
        }
    };
    
    useEffect(() => {
        scrollToBottom();
    }, [isAwaitResponseMessage]);
    
    return (
        <div className="ia-default-container">
            <header className='ia-header'>
                <div className='right-header'>
                    <div className='header-squadia'>
                        <img src={Logo} />
                        <span className='info-ia'>
                            <p>Squad <strong>AI</strong></p>
                            <p className='preview-alert'>Preview</p>
                        </span>
                    </div>
                </div>
                <div className='left-header'>
                    {assistantId !== null && 
                        <div onClick={scrollToTop} className='header-long-item-click'>
                            <p>Mudar assistente</p>
                        </div>
                    }
                    {(assistantId !== null && messages.length > 1) && 
                        <Tooltip title="Limpar conversa">
                            <div onClick={clearChat} className='header-item-click'>
                                <Clear theme="outline" size="16" fill="#000000"/>
                            </div>
                        </Tooltip>
                    }
                        

                    {/* {!isAtBottom && 
                    <Tooltip title="Fechar">
                        <div className='header-item-click'>
                            <i className='mdi mdi-close' onClick={() => setActiveIA(false)} />
                        </div>
                    </Tooltip>} */}
                    <Tooltip title="Fechar">
                        <div className='header-item-click'>
                            <i className='mdi mdi-close' onClick={() => setActiveIA(false)} />
                        </div>
                    </Tooltip>
                </div>
             
            </header>
            <main className='ia-content'>
                {isLoading 
                    ?
                        <div className='ia-content-loading'>
                            <div className='content-logo'>
                                <PulseLoader speedMultiplier={1} size={12} color='rgba(10, 44, 115, .5)' />
                            </div>
                        </div>
                    :     
                    <>  
                        <section className='results-area' ref={resultsAreaRef}>
                            <AssistantBallon
                                selected={assistantId}
                                onSelect={(v) => switchAssistant(v)}
                                filtredAssistants={filtredAssistants}
                            />
                            {
                                messages && messages.length > 0 && 
                                <Messages 
                                    ref={loadFirst}
                                    key={messages.length}
                                    messages={messages}
                                    changeNewMessages={value => [setMessages(value), scrollToTop]}
                                    scrollToBottom={() => scrollToBottom()}
                                    isLoadingMessage={isAwaitResponseMessage}
                                    restoreLoading={restoreLoading}
                                    setRestoreLoading={value => setRestoreLoading(value)}
                                    restoreInfo={{
                                        token: tokenCache.token,
                                        assistant_info: filtredAssistants.find(item => item.id_chatgpt === assistantId)
                                    }}
                                    selectedMessages={selectedMessages}

                                    clearSelectMessage={() => setSelectedMessages([])}

                                    toggleMessageSelection={toggleMessageSelection}
                                    setOpenModalExport={(value) => setOpenModalExport(value)}
                                    setIsOpeSendEmailExport={(value) => setIsOpeSendEmailExport(value)}
                                    isOpenSendEmailExport={isOpenSendEmailExport}
                                    

                                    openModalExport={openModalExport}
                                    closeModalExport={() => {
                                        setOpenModalExport(false)
                                        setSelectedMessages([])
                                    }}


                                />
                            }
                        </section>  
                        <form className='typing-area' onSubmit={sendPromptF}>
                            <Tooltip title="Você precisa selecionar um assistente" followCursor open={assistantId === null}>
                                <div className='input-box'>
                                    <textarea
                                        disabled={assistantId === null} 
                                        className='typing-text-ia'
                                        placeholder='Pergunte ao Squad AI..'
                                        value={words}
                                        onChange={e => {
                                            if (e.target.value.length <= 500) {
                                                setWords(e.target.value);
                                            }
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault()
                                                if(words.length > 0) {
                                                    sendPrompt()
                                                }
                                            }
                                        }}
                                    />
                                    <div className='words-send-btn'>
                                        <p>{`${words.length}/500`}</p>
                                        <i className='mdi mdi-send button-chat-msg' onClick={() => sendPrompt()} />
                                    </div>
                                </div>
                            </Tooltip>
                        </form>
                    </>
                }
            </main>
            {isOpenSendEmailExport && <ModalEmail setIsOpeSendEmailExport={(e) => setIsOpeSendEmailExport(e)} item={selectedMessages} />}
        </div>

        
    )
}

export default withRouter(SquadIaDfeault)