import React, { useEffect, useContext, useState } from "react";
import "./styles.scss";

import DefaultButton from "app/pages/admin/novosRelatorios/Editar/components/defaultButton";
import AreaInput from "../../pages/admin/novosRelatorios/Editar/components/AreaInput";
import api from "services/api/api";
import { AuthContext } from "contexts/appProvider";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Swal from 'sweetalert2';
import Loader from "../../../assets/icons/circulo_loader.webp";

const ModalEmail = ({ setIsOpeSendEmailExport, item }) => {
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { userData } = useContext(AuthContext);
  const [message, setMessage] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const userAuth = userData;

  const disabled = !selectedUsers || isLoading || selectedUsers.length === 0;

  const getAll = async () => {
    try {
      await RequestUsers();
    } catch (error) {
      console.error("Erro ao buscar informações dos usuários:", error);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  async function RequestUsers() {
    try {
      const res = await api.post("/users");
      const { usuarios } = res.data;
      const usuariosFilter = usuarios.filter((item) => item.status === 1);

      // Filtragem de usuários conforme o tipo de login
      if (userAuth.login === "admin") {
        const userAdmin = usuariosFilter.filter((item) => item.status === 1);
        if (userAdmin.length > 0) {
          const allListUsers = userAdmin
            .map((item) => ({
              label: item.nome,
              value: item.id_usuario,
              email: item.email,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setUsersOptions(allListUsers);
        }
      } else {
        const notAdminUser = usuariosFilter.filter(
          (item) => item.login !== "admin"
        );
        if (notAdminUser.length > 0) {
          const listNotUserAdmin = notAdminUser
            .map((item) => ({
              label: item.nome,
              value: item.id_usuario,
              email: item.email,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setUsersOptions(listNotUserAdmin);
        }
      }
    } catch (error) {
      console.error("Erro na requisição de usuários:", error);
    }
  }

  async function handleSendEmailPdf(data) {
    console.log('executou')
    try {
      setIsLoading(true);
      const doc = new jsPDF('l');
      doc.setTextColor(0, 120, 215);

      const maxLinesPerPage = 13;

      const allRows = [];
      const headers = data.length > 0 && data[0].message.value.length > 0
        ? Object.keys(data[0].message.value[0]).map(header => header.replace(/\[|\]/g, ''))
        : [];

      data.forEach(item => {
        const rows = item.message.value.length > 0 ? item.message.value.map(value => Object.values(value)) : [];
        allRows.push(...rows);
      });

      const tableOptions = {
        head: [headers],
        theme: 'grid',
        headStyles: {
          fillColor: [0, 120, 215],
          textColor: [255, 255, 255],
          fontStyle: 'bold',
          halign: 'left',
          cellPadding: 5,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineHeight: 1.5,
          cellPadding: 4,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        styles: {
          minCellHeight: 10,
          fontSize: 10,
          halign: 'left',
          tableWidth: doc.internal.pageSize.width - 20,
        },
      };

      let i = 0;
      while (i < allRows.length) {
        const rowsToShow = allRows.slice(i, i + maxLinesPerPage);
        const startY = 16;

        doc.autoTable({
          ...tableOptions,
          startY: startY,
          body: rowsToShow,
        });

        i += rowsToShow.length;

        if (i < allRows.length) {
          doc.addPage();
        }
      }

      const pageCount = doc.internal.getNumberOfPages();
      const footerMargin = 10;
      const footerText = "Resultado gerado por [Squad AI/Squadbi LTDA]";
      for (let pageIndex = 1; pageIndex <= pageCount; pageIndex++) {
        doc.setPage(pageIndex);
        doc.setFontSize(10);
        doc.setFont("Helvetica", "normal");
        doc.text(footerText, 14, doc.internal.pageSize.getHeight() - footerMargin);
      }

      const pdfBlob = await doc.output('blob');

      const formData = new FormData();
      formData.append('file', pdfBlob);


      const emails = selectedUsers.map(user => user.email);
      formData.append('emails', JSON.stringify(emails));
      if(message){
        formData.append('message', message)
      }




      const response = await api.post('/sendResultAi', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


      if (response.status === 200) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Enviado com sucesso!",
          showConfirmButton: false,
          timer: 5000
        });
      } else {
        Swal.fire(
          "Oops, erro!",
          "Não foi possível enviar o email, tente novamente!",
          "error"
        )
      }
    } catch (error) {
      console.error('Erro ao gerar e enviar PDF por e-mail:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="popup-overlay-email">
      <div className="popup-email">
        <div className="close">
          <i
            className="modal-last-updates-close mdi mdi-close"
            onClick={() => setIsOpeSendEmailExport(false)}
            style={{ cursor: 'pointer' }}
          />
        </div>

        <div className="popup-header">
          <div className="description-email">
            <span className="action-header">Compartilhar <i className="mdi mdi-share" /> </span>
            {/* <span className="footer-header">SquadAI 2024</span> */}
          </div>
        </div>

        <div className="content-email-modal">
          <p className="modal-description">
            Escolha os usuários com quem você deseja compartilhar os resultados desta consulta.
          </p>

          {/* Exibição de carregamento, se necessário
          {isLoading && (
            <div className="sync-loading">
              <CircularProgress size={24} />
            </div>
          )} */}

          <AreaInput
            type="selectReact"
            label=""
            inputProps={{
              id: "exampleSelectTipoUsuario",
              placeholder:
                selectedUsers && selectedUsers.length > 0
                  ? `Usuários selecionados: ${selectedUsers.length}`
                  : "Nenhum usuário selecionado.",
              noOptionsMessage: () => "Nenhum usuário encontrado.",
              controlShouldRenderValue: false,
              closeMenuOnSelect: false,
              options: usersOptions || [],
              value: selectedUsers,
              onChange: (selectedValue) => setSelectedUsers(selectedValue),
            }}
          />

          <div className="optional-message-container">
            <textarea
              id="optionalMessage"
              placeholder="Mensagem opcional..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="optional-message-input"
            />
          </div>


          <div className="buttons-send-email">
            {/* <DefaultButton
              action="save"
              placeholder="Enviar"
              execution={() => handleSendEmailPdf(item)}
              isDisabled={selectedUsers.length === 0}
            /> */}

            <button
              type={'submit'}
              className={
                `save-button-container-email save ${disabled && 'disabled'}`
              }
              disabled={disabled}
              onClick={() => handleSendEmailPdf(item)}
            >
              {isLoading ? <img src={Loader} style={{ width: '21px' }} /> : <>
                Enviar
              </>}
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEmail;
