import './styles.scss'
import React, { useState } from 'react'

export function ModalConfirmEditReport({ isOpen, setIsOpen, setModeAction }) {
    return (
        <>
            {isOpen && (
                <main className='modal-report-edit-container'>
                    <div className='modal-report-edit-content'>
                        <header className='report-edit-header'>
                            <h1 className='report-edit-title'>Alterações não salvas</h1>
                            <p className='report-edit-description' style={{marginTop: '6px'}}>
                               Deseja salvar as alterações a este relatório?
                            </p>
                        </header>
                        <footer className='report-edit-footer'>
                            <button className='report-edit-btn start-edit-btn' onClick={() => {
                                 window.report.save()
                                 window.report.switchMode('view')
                                 setIsOpen(false)
                                 setModeAction('view')
                            }}>
                              Salvar
                            </button>

                            <button className='report-edit-btn not-save-btn-confirm' onClick={() => {
                                window.report.switchMode('view')
                                window.report.reload()
                                setIsOpen(false)
                                setModeAction('view')
                            }}>
                                Não salvar
                            </button>

                            <button className='report-edit-btn close-btn-confirm' onClick={() => {
                                setIsOpen(false)
                            }}>
                                Cancelar
                            </button>
                        </footer>
                    </div>
                </main>
            )}
        </>
    )
}
