import { useTranslation } from 'react-i18next';

const useMonitoringHubData = () => {
  const { t } = useTranslation();

  const data = [
    [
      `1-${t('sidebar.monitoring')}`,
      "CompassOne"
    ]
  ];

  return data;
};

export default useMonitoringHubData;