import './styles.scss'
import axios from 'axios'
import { Loading } from '../loading'
import React, { useState, useEffect, forwardRef } from 'react'
import { BotMessage } from './bot-message'
import { UserMessage } from './user-message'

const Messages = forwardRef(({
    messages,
    changeNewMessages,
    scrollToBottom,
    isLoadingMessage,
    restoreLoading,
    setRestoreLoading,
    restoreInfo,
    selectedMessages,
    clearSelectMessage,
    toggleMessageSelection,
    setOpenModalExport,
    setIsOpeSendEmailExport,
    openModalExport,    
    closeModalExport,
    isOpenSendEmailExport
}, ref) => {

    const [historyMessages, setHistoryMessages] = useState([]);
    const [animated, setAnimated] = useState(null);

    useEffect(() => {
        const loadMessages = async () => {
            if (!ref.current) {
                await loadLastMessage(); 
                ref.current = true; 
            }
        };

        loadMessages();
    }, [])

    async function handleAnimatedResponse(message) {
        setAnimated(message);
        await new Promise(r => setTimeout(r, 2000));
        setAnimated(null);
    }

    async function restoreQuerie(item) {
        try {
            const message = item.message
            const query = JSON.parse(removeJsonIfPresent(message)).querie_dax
            const config = { headers: { 'Authorization': 'Bearer ' + restoreInfo.token } };
            const assistant_info = restoreInfo.assistant_info
            const body = {
                "queries": [
                    {
                        "query": query,
                    }
                ]
            };
    
            const response = await axios.post(`https://api.powerbi.com/v1.0/myorg/groups/${assistant_info.workspace}/datasets/${assistant_info.dataset}/executeQueries`, body, config);
    
            if (response.status === 200 && response.data && response.data.results && response.data.results[0].tables[0].rows) {
                
                const rows = response.data.results[0].tables[0].rows;
                

                if (rows.length === 0) {
                    return {
                        "value": [], 
                        "querie": query, 
                        "description": JSON.parse(removeJsonIfPresent(message)).descricao_dax, 
                    };
                } else {
                    return {
                        "value": rows, 
                        "querie": query, 
                        "description": JSON.parse(removeJsonIfPresent(message)).descricao_dax, 
                    };
                }
            }
    
        } catch (error) {
            return null;
        }
    }

    function removeJsonIfPresent(str) {  
        const regex = /```(?:json)?\s*([\s\S]*?)\s*```/g;
        const cleanedString = str.replace(regex, '$1');
        return cleanedString.trim();
    }

    async function loadLastMessage() {
        try {

            if(restoreLoading) {
                return;
            }

            setRestoreLoading(true)
            let newMessages = [...messages]; 
            let lastBotMessageIndex = -1;
            let lastUserMessageIndex = -1;
    
            for (let i = newMessages.length - 1; i >= 0; i--) {
                if (lastBotMessageIndex === -1 && newMessages[i].isBot && newMessages[i].oldMessage) {
                    lastBotMessageIndex = i;
                }
                if (lastUserMessageIndex === -1 && !newMessages[i].isBot && newMessages[i].oldMessage) {
                    lastUserMessageIndex = i;
                }
    
                if (lastBotMessageIndex !== -1 && lastUserMessageIndex !== -1) {
                    break;
                }
            }
    
            if (lastBotMessageIndex !== -1) {
                try {
                    const startTime = new Date();
                    const restoredMessage = await restoreQuerie(newMessages[lastBotMessageIndex]);
                    const endTime = new Date();
                    const timeTaken = endTime - startTime;

                    if(restoredMessage) {

                        newMessages = newMessages.map((message, index) => {
                            if (index === lastBotMessageIndex) {
                                return {
                                    ...message,
                                    message: {
                                        querie: restoredMessage.querie,
                                        description: restoredMessage.description,
                                        value: restoredMessage.value,   
                                        time: timeTaken                        
                                    },
                                    oldMessage: false
                                };
                            }
                            return message;
                        });

                    } else {

                        newMessages = newMessages.map((message, index) => {
                            if (index === lastBotMessageIndex) {
                                return {
                                    ...message,
                                    message: {
                                        querie: JSON.parse(removeJsonIfPresent(message.message)).querie_dax,
                                        description: JSON.parse(removeJsonIfPresent(message.message)).descricao_dax,
                                        value: [],   
                                        time: timeTaken                         
                                    },
                                    oldMessage: false
                                };
                            }
                            return message;
                        });

                    }
                } catch (error) {
                    console.error('Erro ao restaurar a mensagem do bot:', error);
                }
            }
    
            if (lastUserMessageIndex !== -1) {
                newMessages = newMessages.map((message, index) => {
                    if (index === lastUserMessageIndex) {
                        return { ...message, oldMessage: false };
                    }
                    return message;
                });
            }
    
            setRestoreLoading(false)
            changeNewMessages(newMessages);    
        } catch (error) {
            setRestoreLoading(false)
            console.error(error);
        }
    }  

    return (
        <main className='messages-structure'>
            {messages.length > 1 && messages.filter((item) => item.oldMessage).length > 0 &&
                <div className='messages-structure-more-messages'>
                    <div 
                        className={
                            `load-more-messages-btn ${restoreLoading && 'active'}`
                        } 
                        onClick={loadLastMessage}>
                        <p>Carregar mensagens anteriores</p>
                    </div>
                </div>
            }
            {
                historyMessages
            }
            {messages.filter((item) => !item.oldMessage).map((item, key) => {
                if(item.isBot) {
                    return <BotMessage 
                                key={key} 
                                item={item}
                                toggleMessageSelection={toggleMessageSelection}
                                animated={item.message === animated} 
                                setOpenModalExport={setOpenModalExport}
                                setIsOpeSendEmailExport={(e) => setIsOpeSendEmailExport(e)}
                                clearSelectMessage={clearSelectMessage}
                                openModalExport={openModalExport}
                                closeModalExport={closeModalExport}
                                selectedMessages={selectedMessages}
                                isOpenSendEmailExport={isOpenSendEmailExport}

                                scrollToBottom={() => scrollToBottom()}
                                handleAnimated={
                                    (value) => handleAnimatedResponse(value)
                                }
                            />
                } else {
                    return <UserMessage key={key} item={item} />
                }
            })}
            {isLoadingMessage && <Loading />}
        </main>
    )
})

export default Messages;