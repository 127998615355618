import './styles.scss'
import React, { useState } from 'react'

export function ModalEditReport({ isOpen, setIsOpen, setModeAction }) {
    return (
        <>
            {isOpen && (
                <main className='modal-report-edit-container'>
                    <div className='modal-report-edit-content'>
                        <header className='report-edit-header'>
                            <h1 className='report-edit-title'
                                style={{
                                    display: 'flex',
                                    height: 'auto',
                                    alignItems: 'center',
                                    gap: '10x'
                                }}
                            >Edição finalizada com sucesso <i className='mdi mdi-check-circle'
                                style={{
                                    color: '#117865',
                                    fontSize: '25px'
                                }}
                            ></i></h1>
                            <p className='report-edit-description'>
                                As alterações no relatório foram concluídas com sucesso e o processo foi finalizado, você ira retornar para a visualização do relatório.
                            </p>
                        </header>
                        <footer className='report-edit-footer'>
                            {/* <button className='report-edit-btn start-edit-btn' onClick={() => setIsOpen(false)}>
                                Nova Edição
                            </button> */}

                            <button className='report-edit-btn start-edit-btn' onClick={() => {
                                window.report.switchMode('view')
                                setIsOpen(false)
                                setModeAction('view')
                            }}>
                                Concluído
                            </button>
                        </footer>
                    </div>
                </main>
            )}
        </>
    )
}
